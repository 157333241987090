// Header.js
import React, {Component} from 'react';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import moment from 'moment';
import { CSVLink } from "react-csv";
import matchSorter from 'match-sorter'
import ReactTable from "react-table";
import DateTimePicker from 'react-datetime-picker';

import './style.css';

class ProductContent extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            upc_search: '',            
            orders: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),            
            cl_name: '',
            pr_id: this.props.productId,
            pr_sku: '',
            pr_desc: '',
            pr_upc: '',
            pr_ean: '',
            pr_qty: 0,
            pr_price: 0,
            pr_other1: 0,
            pr_weight: 0,
            pr_volume: 0,
            pr_billing_cost: 0,
            pr_qty_floor: 0,
            pr_bill_level: 0,
            pr_date_added: '',
            pr_date_counted: '',
            productbins: [],
            productcases: [],
            productkits: [],
            inventoryitems: [],
            start_date: '2021/1/1',
            startdate:null,
            enddate:null,
            sortOptions: [{ id: 'lg_id', desc: true }],
            userToken: localStorage.getItem('userToken'),
            us_staff: localStorage.getItem('us_staff')
        };
       
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getProductBins = this.getProductBins.bind(this);
        this.getProductKits = this.getProductKits.bind(this);
        this.getProductCases = this.getProductCases.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.filterReport = this.filterReport.bind(this);

    }

      getProduct() {
      
        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/'+this.props.productId,
        {
            method: 'GET',      
            headers: {
            'Accept': 'application/json',
            'bwToken': this.state.userToken
            }

            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(res => res.json())
        .then(product => this.setState({
          cl_id:product[0].cl_id || '',
          cl_name:product[0].cl_name || '',
          pr_id:product[0].pr_id || '',
          pr_sku:product[0].pr_sku || '',
          pr_desc:product[0].pr_desc || '',
          pr_upc:product[0].pr_upc || '',
          pr_ean:product[0].pr_ean || '',
          pr_qty:product[0].pr_qty || 0,
          pr_price:product[0].pr_price || 0,
          pr_other1:product[0].pr_other1 || 0,
          pr_weight:product[0].pr_weight || 0,
          pr_volume:product[0].pr_volume || 0,
          pr_billing_cost:product[0].pr_billing_cost || 0,
          pr_qty_floor:product[0].pr_qty_floor || 0,
          pr_bill_level: product[0].pr_bill_level || 0,
          pr_date_added:product[0].pr_date_added || '',
          pr_date_counted:product[0].pr_date_counted || ''
        }));
        
      }

    getProductBins = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productbins => this.setState({ productbins }));
    }
    
    getProductKits = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productkits => this.setState({ productkits }));
    }

    getProductCases = (url) => {
      fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(productcases => this.setState({ productcases }));
    }

    getOrders = (prid) => {
      var url = process.env.REACT_APP_NODE_ROOT_URL+'/orders/o/product/'+prid;

      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id,
        pr_id: prid
      });

      console.log('logging product json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(orders => this.setState({ orders }));       
      
    }
 
    componentDidMount() {
      this.getProduct();
      this.getProductBins(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/'+this.props.productId);  
      this.getProductCases(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/'+this.props.productId);
      this.getProductKits(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/single/'+this.props.productId);
      this.getOrders(this.props.productId)
      this.getInventoryLog(process.env.REACT_APP_NODE_ROOT_URL+'/reports/inventorylog/'+this.state.cl_id);      
    }

    filterReport = (url) => {
      this.getInventoryLog(process.env.REACT_APP_NODE_ROOT_URL+'/reports/inventorylog/'+this.state.cl_id);     
    }

    getInventoryLog = (url) => {
      
      let _startdate = this.state.startDate;
      let _enddate = this.state.endDate;

      if (_startdate==null) {
        _startdate = moment().startOf('month').format('YYYY-MM-DD');
        _enddate   = moment().endOf('month').format('YYYY-MM-DD');      
      } else {
        _startdate = moment(this.state.startDate).format('YYYY-MM-DD');
        _enddate = moment(this.state.endDate).format('YYYY-MM-DD');
      }
             
       var inventoryJSON = JSON.stringify({
         cl_id: this.state.cl_id,
         pr_id: this.props.productId,
         start_date: _startdate,
         end_date: _enddate
       }); 
             
       fetch(url, {
             method: 'post',        
             body: inventoryJSON,      
             headers: {            
             'Content-Type': 'application/json',
             'bwToken': this.state.userToken
         }             
       }).then(res => res.json())
         .then(inventoryitems => this.setState({ inventoryitems })); 
     }  

    getInventory = (url) => {
      
      var _startdate = '2020-10-7';

      if (this.state.cl_id === 2) {
        _startdate  = '2021-1-6';
      }
    
      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id,
        pr_id: this.props.productId,
        start_date: _startdate
      });

      console.log('logging inventory json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(inventoryitems => this.setState({ inventoryitems }));       
      
    }  

    handleInputChange(event) {     
      
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
       [name]: value
      });

    }


    handleSubmit(event) {  
      event.preventDefault();
     
      let _productSku = this.state.pr_sku;
      let _productUpc = this.state.pr_upc;

      if (_productSku == '' || _productUpc == '') {

        alert('Every product needs a SKU and UPC');  
        return false;

      } else {

        var productJSON = JSON.stringify({
          pr_sku: this.state.pr_sku,
          pr_desc: this.state.pr_desc,
          pr_upc: this.state.pr_upc,
          pr_ean: this.state.pr_ean,
          pr_qty: this.state.pr_qty,
          pr_price: this.state.pr_price,
          pr_other1: this.state.pr_other1,
          pr_weight: this.state.pr_weight,
          pr_volume: this.state.pr_volume,
          pr_qty_floor: this.state.pr_qty_floor,
          pr_bill_level: this.state.pr_bill_level,
          pr_date_added: this.state.pr_date_added,
          pr_date_counted: this.state.pr_date_counted
          });
  
        console.log(productJSON);
        
        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/'+this.props.productId, {
              method: 'post',        
              body: productJSON,      
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken
              }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
          }).then(function(response) {            
              return response.json();
          }).then(function(data) {
              alert('Product updated successfully'); 
              console.log(data); 
          }).catch(function(error) {
              alert('Product could not be updated'); 
              console.log('submit err: ' + error);       
          });

      }

    } 

render(){
        
        const { orders, inventoryitems } = this.state;

        return (

        <div className="page-wrapper" id="main">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Product Information</h3> </div>
                <div className="col-md-7 align-self-center">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/products">Products</a></li>
                        <li className="breadcrumb-item active">{this.state.pr_sku}</li>
                    </ol>
                </div>
            </div>

            <div className="container-fluid">               
            <form onSubmit={this.handleSubmit}>
            <div className="card">                                                
              <div className="card-body" name="card-order-list">

                <div className="row">  
                    <div className="col-md-4">            

                      <div className="form-group">                         
                          <label>
                            Client: <strong> {this.state.cl_name} </strong>
                          </label>
                          <br/>
                          <label>
                            SKU: <br/>
                            <input
                              name="pr_sku"
                              type="text"
                              value={this.state.pr_sku}
                              className="prod-sku"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Description:<br/>
                            <input
                              name="pr_desc"
                              type="text"
                              value={this.state.pr_desc}
                              className="prod-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            UPC:<br/>
                            <input
                              name="pr_upc"
                              type="text"
                              value={this.state.pr_upc}
                              onChange={this.handleInputChange} />
                          </label> 
                          <br/>
                          <label>
                            EAN:<br/>
                            <input
                              name="pr_ean"
                              type="text"
                              value={this.state.pr_ean}
                              onChange={this.handleInputChange} 
                               />
                          </label> 
                          <br />
                          <label>
                            Date Product Added:<br/>
                            {moment(this.state.pr_date_added).isValid() ? moment(this.state.pr_date_added).format("MM-DD-YYYY hh:mm:ss a"): ""}
                          </label>
                          <br />
                          <label>
                            Date Product Last Counted:<br/>
                            {moment(this.state.pr_date_counted).isValid() ? moment(this.state.pr_date_counted).format("MM-DD-YYYY hh:mm:ss a"): ""}
                          </label>
                          <br/>
                          {this.state.us_staff!=='1' ? (
                          <input type="submit" value="Update" className="btn btn-primary btn-sm"/>
                          ) : (<div></div>) } 
                        </div>
                    </div>               
                    <div className="col-md-2">            

                      <div className="form-group">                         
                         
                          <label>
                            Quantity:<br/>
                            <input
                              name="pr_qty"
                              type="text"
                              disabled={true}
                              value={this.state.pr_qty}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Low QOH Alert Quantity:<br/>
                            <input
                              name="pr_qty_floor"
                              type="text"
                              value={this.state.pr_qty_floor}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Price:<br/>
                            <input
                              name="pr_price"
                              type="text"
                              value={this.state.pr_price}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Weight:<br/>
                            <input
                              name="pr_weight"
                              type="text"
                              value={this.state.pr_weight}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Volume:<br/>
                            <input
                              name="pr_volume"
                              type="text"
                              value={this.state.pr_volume}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Other 1:<br/>
                            <input
                              name="pr_other1"
                              type="text"
                              value={this.state.pr_other1}                              
                              onChange={this.handleInputChange} />
                          </label>
                         
                        </div>
                    </div>
                    <div className="col-md-6">            

                      <div className="form-group">
                          <label>
                            <u><a href="/product-bins">Locations</a></u> <span className="tool-link"></span><br/>                            
                            <ul>
                            {this.state.productbins.map((bin, idx) => {                              
                              let divId = `div-${idx}`;
                              return (
                                  <li key={divId}>{this.state.productbins[idx].bn_bin}</li>
                                )
                              })                
                            }
                            </ul>
                          </label>
                          <br/>
                          <label>
                            <u><a href={'/product-case/' + this.state.cl_id}>Cases</a></u> <span className="tool-link"></span><br/>
                            <ul>
                            {this.state.productcases.map((cs, idx) => {                              
                              let divCsId = `div-${idx}`;
                              return (
                                  <li key={divCsId}>{cs.cs_sku}</li>
                                )
                              })                
                            }
                            </ul>
                          </label>
                          <br />
                          <label>
                          <u><a href={'/product-kits/' + this.state.cl_id}>Kits</a></u> <span className="tool-link"></span><br/>
                            <ul>
                            {this.state.productkits.map((kt, idx) => {                              
                              let divKtId = `div-${idx}`;
                              return (                                  
                                  <li key={divKtId}><a href={'/product-kit/' + this.state.cl_id + '/' + kt.kt_id}>{kt.kt_sku}</a></li>
                                )
                              })                
                            }
                            </ul>
                          </label>
                          <br />
                          <label>
                            Per Item Charge:<br/>
                            <input
                              name="pr_billing_cost"
                              type="text"
                              value={this.state.pr_billing_cost}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Item Billing Level (1-4):<br/>
                            <input
                              name="pr_bill_level"
                              type="text"
                              value={this.state.pr_bill_level}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          
                        </div>
                    </div>               
                    

              </div>
             <div className="col-md-12">
                      <div>
                      <h3>Inventory Log</h3>          
                      <div className="col-lg-12">
                    Select a Start and End Date for the log to see results.
                            <table>                              
                              <tbody>
                              <tr>
                                <td>
                                Start Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={startDate => this.setState({ startDate })}
                                value={this.state.startDate}
                                format={"MM-dd-y"}
                              />&nbsp;&nbsp;</td>
                                <td>
                                End Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={endDate => this.setState({ endDate })}
                                onBlur={this.filterReport}
                                value={this.state.endDate}
                                format={"MM-dd-y"}
                              /></td>
                              </tr>
                              </tbody>
                            </table>
                           <br/>
                            <div className="card-body" name="card-order-list">
                            <CSVLink data={inventoryitems}>Download CSV of Inventory Tracking</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={inventoryitems}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading inventory tracking report...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}       
   
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Product ID",
                                              accessor: "pr_id",
                                              width: 10,
                                              show: false,
                                              filterable: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                              
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              width: 125,                                                                                          
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            }, 
                                            {
                                                Header: "Receiver Number",
                                                accessor: "rc_number",
                                                width: 125,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            }, 
                                            {
                                                Header: "Order Number",
                                                accessor: "or_ss_order_id",
                                                width: 125,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                                            
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Qty In</div>
                                              ),
                                              accessor: "lg_qty_in",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            }, 
                                            {
                                                Header: () => (
                                                  <div style={{ textAlign: "center" }}>Qty Out</div>
                                                ),
                                                accessor: "lg_qty_out",
                                                width: 100,
                                                filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                              }, 
                                              {
                                                Header: () => (
                                                  <div style={{ textAlign: "center" }}>Qty Avail Old</div>
                                                ),
                                                accessor: "lg_qoh_old",
                                                width: 100,
                                                filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                              },
                                              {
                                                Header: () => (
                                                  <div style={{ textAlign: "center" }}>Qty Avail New</div>
                                                ),
                                                accessor: "lg_qoh_new",
                                                width: 100,
                                                filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                              },                                              
                                              {
                                                Header: "User",
                                                accessor: "us_first_name",
                                                width: 80,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                              },
                                              {
                                                Header: "Note",
                                                accessor: "lg_note",
                                                width: 120,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                              },
                                              {
                                                Header: "Log Date",
                                                accessor: "lg_entry_date",
                                                width: 140,
                                                filterMethod: (filter, row) =>
                                                  row[filter.id].startsWith(filter.value),
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                                )
                                              } 
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                
                       
                    </div>
                </div>                
  
                      </div>
                      <br/><br/>
                      <h3>Related Orders</h3>
                      <CSVLink data={this.state.orders}>Download CSV of Related Orders</CSVLink>
                      <div className="table-responsive">
                                   
                                     <ReactTable
                                      data={orders}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value} 
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "cl_id",
                                              accessor: "cl_id",
                                              show: false
                                            },
                                            {
                                              Header: "or_id",
                                              accessor: "or_id",
                                              show: false
                                            },
                                            {
                                              Header: "Order ID",
                                              accessor: "or_actual_order_id",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_actual_order_id"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Order Date",
                                              accessor: "or_date",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },        
                                            {
                                              Header: "Status",
                                              accessor: "or_status",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value) &&
                                                row[filter.id].endsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Total Items",
                                              accessor: "total_items",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["total_items"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            }                                       
                                          ]
                                        }
                                      ]}
                                      defaultSorted={[
                                        {
                                          id: "or_id",
                                          desc: true
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                          onClick: (e) => {
                                            window.open("/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id, "_new");
                                            //window.location.href = "/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id;
                                            //var _url = "/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id;
                                            //this.handleOriginal(_url);
                                          }
                                        };
                                      }}
                                    />
                                    </div>
             
             </div>   
                      

            </div>     
            </div>
            </form>           

            </div>                      
        </div>

                )
    }
}
export default ProductContent;