import React, {Component} from 'react';
import { CSVLink } from "react-csv";
import ReactTable from "react-table";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';


class RequestDetail extends Component {
 constructor(props) {
        super(props);              

            this.state = {
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId')
            
        };      
      
    }    

    componentDidMount() {
      
    } 
   
    render(){
        
          
        return (
        <div id="main">
        <div className="page-wrapper">
               <div className="row page-titles">
                <div className="col-md-1">
                    <h3 className="text-primary">Requests</h3>                    
                </div>
               
            </div>
            
            <div className="container-fluid">               

              <div className="row">
                    <div className="col-lg-12">
                        <br/><br/>
                       For any new requests, please email <a href="mailto:cs@brandretailers.com">cs@brandretailers.com</a>.
                       
                    </div>
                </div>               

            </div>
        </div>
        </div>

                )
    }
}
export default RequestDetail;