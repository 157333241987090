import React, { Component } from 'react';
import matchSorter from 'match-sorter';
import moment from 'moment';
import SelectTable from 'react-table';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css'
import './style.css'
import PropTypes from 'prop-types';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '400px',
      color                 : 'black'
    },
    overlay: {zIndex: 1000}
  };

class OrderListPaged extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        attachments:[],
        orders: [],            
        orderitems: [],
        cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),   
        customerOrderEntry: typeof(localStorage.getItem('customerOrderEntry')) === 'undefined' ? 0 : localStorage.getItem('customerOrderEntry'),                     
        or_id: '',
        del_or_id: '',
        or_number: '',
        disableAddButton:true,
        filesModalIsOpen: false,
        importModalIsOpen: false,
        importstatus: '',
        loading: false,
        selectAll: false,
        selection: [],
        selectedIds: [],
        userToken: localStorage.getItem('userToken'),
        us_staff: localStorage.getItem('us_staff'),
        page: 0,
        pageSize: 100,
        totalPages: 0,
        sorted: [],
        filtered: []
    };
    
    this.fetchOrders = this.fetchOrders.bind(this);
    
    this.getAttachments = this.getAttachments.bind(this);
    this.getShipstationOrderById = this.getShipstationOrderById.bind(this);             
    this.openFilesModal = this.openFilesModal.bind(this);
    this.closeFilesModal = this.closeFilesModal.bind(this);

    this.importModalIsOpen = this.importModalIsOpen.bind(this);
    this.closeImportModal = this.closeImportModal.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    
  }

  componentDidMount() {
    
    this.handleClientChange(this.state.cl_id);       
    Modal.setAppElement('#main');
  }

  static defaultProps = {
        keyField: "or_id"
      };
    
  static propTypes = {
    keyField: PropTypes.string
  };

  handleInputChange(event) {     
    
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }  

  handleClientChange = (cl_id) => {
        
    if (cl_id === 23) {
      //this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders');
      this.fetchOrders();
      this.setState({disableAddButton:true});
    } else {
      this.fetchOrders();
      //this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/'+cl_id);
      this.setState({disableAddButton:false});
    }        
}

  fetchOrders = () => {
    const { page, pageSize, sorted, filtered, cl_id } = this.state;
    this.setState({ loading: true });

    const sortField = sorted.length ? sorted[0].id : 'or_id';
    const sortOrder = sorted.length ? (sorted[0].desc ? 'desc' : 'asc') : 'desc';
    const filterParams = filtered.map(f => `&${encodeURIComponent(f.id)}=${encodeURIComponent(f.value)}`).join('');

    fetch(`${process.env.REACT_APP_NODE_ROOT_URL}/orders/paged/${cl_id}/?page=${page + 1}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}${filterParams}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'bwToken': localStorage.getItem('userToken')
      }
    })
    .then(res => res.json())
    .then((response) => {
      const orders = response.orders || [];
      const totalCount = response.total || orders.length;

      this.setState({
        orders,
        totalPages: Math.ceil(totalCount / pageSize),
        loading: false
      });
    })
    .catch((error) => {
      console.error('Error fetching orders:', error);
      this.setState({ loading: false });
    });
  };

  /**
 * Toggle a single checkbox for select table
 */
  toggleSelection = (key, shift, row) => {
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    var selection = [];
    var selectedIds = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      
      currentRecords.forEach(item => {
        selection.push(`select-${item._original[keyField]}`);
        selectedIds.push(item._original[keyField]);
      });
    } else {
      selection = [];
      selectedIds = [];
    }
    this.setState({ selectAll, selection, selectedIds });
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected = key => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => {
    if (!rowInfo || !rowInfo.original) return {};
    const { selection, selectedIds } = this.state;
    const { keyField } = this.props;
      
    return {
      onClick: (e, handleOriginal) => {
        //console.log("It was in this row:", rowInfo);

        var isSelected = selectedIds.includes(rowInfo.original[keyField]);

        if (!isSelected) {
          selection.push(`select-${rowInfo.original[keyField]}`);
          selectedIds.push(rowInfo.original[keyField]);
        } else {
          const index = selectedIds.indexOf(rowInfo.original[keyField]);
          if (index > -1) {
            selectedIds.splice(index, 1);
          }
          const indexData = selection.indexOf(`select-${rowInfo.original[keyField]}`);
          if (indexData > -1) {
            selection.splice(indexData, 1);
          }

        }
                  
        this.setState({ selectedIds, selection });

        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      },       
      style: {               
        background:rowInfo &&            
        selection.includes(`select-${rowInfo.original[keyField]}`) &&
        "lightgreen"
      }
    };
  };

  setRowColor = (rowInfo,selection,keyField) => {      
        if (selection.includes(`select-${rowInfo.original[keyField]}`)) {
          return 'lightgreen';
        } else {
          if (rowInfo.row.or_urgent===1) {
            console.log('urgent')
            return 'lightred';
          }
        }            
  }   
  
  handlePageChange = (page) => {
    this.setState({ page }, this.fetchOrders);
  };

  handlePageSizeChange = (pageSize, page) => {
    this.setState({ pageSize, page }, this.fetchOrders);
  };

  handleSortChange = (sorted) => {
    this.setState({ sorted }, this.fetchOrders);
  };

  handleFilterChange = (filtered) => {
    this.setState({ filtered }, this.fetchOrders);
  };

      //Files
      openFilesModal(e) {
        let _or_id = e.original.or_id;
        this.getAttachments(_or_id);
        this.setState({filesModalIsOpen: true});
  }
  
  afterOpenFilesModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
  }
  
  closeFilesModal() {
        this.setState({filesModalIsOpen: false});
  }

  importModalIsOpen(e) {      
    this.setState({importModalIsOpen: true});
  }

  afterOpenImportModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
  }

  closeImportModal() {
        this.setState({importModalIsOpen: false});
  }

  getAttachments(or_id) {

    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/files/order/'+or_id, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(attachments => this.setState({ attachments }));
  
  }
  
  getOrders = (url) => {       
    this.setState({loading:true});
      fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(orders => this.setState({ orders })).then(this.setState({loading:false}));       
  }

  getShipstationOrderById = () => {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/import/orders/id/'+this.state.cl_id+'/'+this.state.or_number, {
      method: 'GET',
      headers: {
      'Accept': 'application/json',
      'bwToken': this.state.userToken
      }      
  }).then(res => res.json())
    .then(function(data) { 
     //console.log('made it here')
     var obj1 = JSON.parse(data);         
     //if (obj1.status === 'New orders have been imported') {
        alert(obj1.status);
        window.location.reload();
     //}        
     
  });
}

  getShipstationOrders = () => {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/import/client/orders/'+this.state.cl_id, {
        method: 'GET',
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(function(data) { 
       var obj1 = JSON.parse(data);         
       //if (obj1.status === 'New orders have been imported') {
          alert(obj1.status);
          window.location.reload();
       //}        
       
    });
  }

  getShipstationShipments = () => {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/import/shipments/client/'+this.state.cl_id, {
        method: 'GET',
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(function(data) { 
       var obj1 = JSON.parse(data);
       alert(obj1.status); 
       window.location.reload();
    });

  }

  render() {
    const { orders, loading, totalPages, selectAll, cl_id } = this.state;

    return (
      <div id="main">
              <div className="page-wrapper">
                  <div className="row page-titles">  
                   <div className="col-md-1 align-self-center">
                          <h3 className="text-primary">Orders</h3>  
                      </div>
                      <div className="col-md-11 align-self-center">
                      {this.state.cl_id =='69' ? (  
                          <button disabled={false} type="button" className="btn btn-info btn-sm" onClick={this.importModalIsOpen}>Import Missing Order</button>
                      ) : (<div></div>) } 
                          <Link to={`/customer-orderentry/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.customerOrderEntry===0}>Add Order</button></Link>
                      </div>
                  </div>
      
                  <div className="container-fluid">               
      
                      <div className="row">					
                          <div className="col-lg-12">
                              <div className="card">                          
                                  <div className="card-body" name="card-order-list">
                                      <div className="table-responsive">
                                      
                                        <SelectTable
                                          {...this.props}
                                          ref={r => (this.checkboxTable = r)}
                                          data={orders}
                                          loading={loading}
                                          pages={totalPages}
                                          manual
                                          onPageChange={this.handlePageChange}
                                          onPageSizeChange={this.handlePageSizeChange}
                                          onSortedChange={this.handleSortChange}
                                          onFilteredChange={this.handleFilterChange}
                                          toggleSelection={this.toggleSelection}
                                          selectAll={selectAll}
                                          selectType="checkbox"
                                          toggleAll={this.toggleAll}
                                          isSelected={this.isSelected}
                                          getTrProps={this.rowFn}
                                          filterable
                                          loadingText='Looking for orders...'
                                          noDataText='No orders found'
                                          defaultPageSize={100}
                                          className='-striped -highlight'
                                          columns={[
                                            {                                          
                                              columns: [                                   
                                                
                                                {
                                                  Header: "Details",
                                                  accessor: "or_id",
                                                  width: 50,
                                                  show: true,
                                                  Cell: props => (
                                                    <div style={{ textAlign: "center" }}><button onClick={() => {  window.open("/customer-order/"+props.original.cl_id+"/"+props.original.or_id, "_new"); } } className="btn btn-info btn-sm" title="view order detail">view</button></div>
                                                  )
                                                },
                                                {
                                                  Header: "Order Key",
                                                  accessor: "or_id",
                                                  width: 50,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_id"] }),
                                                  filterAll: true,
                                                  show: false,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "center"}}>{row.value}</div>
                                                  )
                                                },                                                                                  
                                                {
                                                  Header: "Order ID",
                                                  accessor: "or_actual_order_id",
                                                  width: 75,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_actual_order_id"] }),
                                                  filterAll: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "center"}}>{row.value}</div>
                                                  )
                                                },
                                                {
                                                  Header: "Order Date",
                                                  accessor: "or_date",
                                                  width: 150,
                                                  filterMethod: (filter, row) =>
                                                    row[filter.id].startsWith(filter.value),
                                                  Cell: row => (
                                                    <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                                  )
                                                },  
                                                {
                                                  Header: "Ship By Date",
                                                  accessor: "or_ship_by",
                                                  width: 75,
                                                  filterMethod: (filter, row) =>
                                                    row[filter.id].startsWith(filter.value),
                                                  Cell: row => (
                                                    <div style={{ textAlign: "center" }}>{moment(row.value).isValid() ? moment(row.value).format("MM/DD/YYYY hh:mm:ss a") : ""}</div>
                                                  )
                                                },                                               
                                                {
                                                  Header: "First Name",
                                                  accessor: "or_ship_fname",
                                                  width: 100,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_ship_fname"] }),
                                                  filterAll: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "left"}}>{row.value}</div>
                                                  )
                                                },
                                                {
                                                  Header: "Last Name",
                                                  accessor: "or_ship_lname",
                                                  width: 100,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_ship_lname"] }),
                                                  filterAll: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "left"}}>{row.value}</div>
                                                  )
                                                },
                                                {
                                                  Header: "Company",
                                                  accessor: "or_ship_company",
                                                  width: 100,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_ship_company"] }),
                                                  filterAll: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "left"}}>{row.value}</div>
                                                  )
                                                },
                                                {
                                                  Header: "Attention",
                                                  accessor: "or_ship_attention",
                                                  width: 100,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_ship_attention"] }),
                                                  filterAll: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "left"}}>{row.value}</div>
                                                  )
                                                },
                                                {
                                                  Header: "Order Type",
                                                  accessor: "or_type",
                                                  width: 100,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_type"] }),
                                                  filterAll: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "left"}}>{row.value}</div>
                                                  )
                                                },
                                                {
                                                  Header: "Other",
                                                  accessor: "or_other",
                                                  width: 100,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_other"] }),
                                                  filterAll: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "left", color: "red"}}>{row.value}</div>
                                                  )
                                                },
                                                {
                                                  Header: "Ship Method",
                                                  accessor: "or_ship_method",
                                                  width: 100,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_ship_method"] }),
                                                  filterAll: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "left"}}>{row.value}</div>
                                                  )
                                                },
                                                {
                                                  Header: "Status",
                                                  accessor: "or_status",
                                                  width:100,
                                                  filterable: true,                                         
                                                  filterMethod: (filter, row) => {
                                                    if (filter.value === "all") {
                                                      return true;
                                                    }
                                                    if (filter.value === "New") {
                                                      return row[filter.id] === "New";
                                                    }
                                                    if (filter.value === "In Process") {
                                                      return row[filter.id] === "In Process";
                                                    }
                                                    if (filter.value === "Canceled") {
                                                      return row[filter.id] === "Canceled";
                                                    }
                                                    if (filter.value === "On Hold") {
                                                      return row[filter.id] === "On Hold";
                                                    }
                                                    if (filter.value === "Ready for Pick Up") {
                                                      return row[filter.id] === "Ready for Pick Up";
                                                    }
                                                    if (filter.value === "Shipped") {
                                                      return row[filter.id] === "Shipped";
                                                    }                                            
                                                  },
                                                  Filter: ({ filter, onChange }) => (
                                                    <select
                                                      onChange={event => onChange(event.target.value)}
                                                      style={{ width: "150" }}
                                                      value={filter ? filter.value : "all"}
                                                    >
                                                      <option value="all">Show All</option>
                                                      <option value="New">New</option>
                                                      <option value="In Process">In Process</option>
                                                      <option value="Canceled">Canceled</option>
                                                      <option value="On Hold">On Hold</option>
                                                      <option value="Ready for Pick Up">Ready for Pick Up</option>
                                                      <option value="Shipped">Shipped</option>
                                                    </select>
                                                  )
                                                },
                                                {
                                                  Header: "BOL",
                                                  accessor: "or_bol",
                                                  width: 50,
                                                  show: true,
                                                  filterMethod: (filter, row) =>
                                                    row[filter.id].startsWith(filter.value),
                                                  Cell: row => (
                                                    <div style={{ textAlign: "center"}}>{row.value==1?"Yes":"No"}</div>
                                                  )
                                                },                                         
                                                {
                                                  Header: "Pick-up Time",
                                                  accessor: "or_wc_time",
                                                  width: 100,
                                                  filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["or_wc_time"] }),
                                                  filterAll: true,
                                                  show: true,
                                                  Cell: row => (
                                                    <div style={{ textAlign: "left"}}>{row.value}</div>
                                                  )
                                                }                                       
                                              ]
                                            }
                                          ]}
                                        />
                                     </div>
                                 </div>                         
     
                             </div>
                         </div>
                     </div>               
                     <div className="row">   
                        <div className="col-lg-4">
                            
                         </div>
                     </div>
     
     
                 </div>
             <div id="modalMain">
               <Modal
                 isOpen={this.state.filesModalIsOpen}                                      
                 style={customStyles}
                 contentLabel="View Order Files"
               >                   
              
                 <div className="container-fluid">               
                  
                       <div className="row">
                           <div className="col-md-12">            
     
                             <div className="form-group" >
                             <u><strong>Attachments</strong></u><br/>
                               <ul>
                               {this.state.attachments.map((attachment,idx) =>
                                 <li key={idx}><a href={"https://bw-admin-files.s3.amazonaws.com/".concat(attachment)} target="_new">{attachment.substr(attachment.lastIndexOf('/') + 1)}</a></li>
                                 )}
                               </ul>                          
                             </div>
                             <div className="modalMessage">{this.state.modalMessage}</div>
                             <div>
                               <button onClick={this.closeFilesModal} className="btn btn-info btn-sm">Close</button>
                             </div>               
                             
                           </div>
     
                           
                       </div>
                      
              
                  </div>                          
                 
               </Modal>
     
               <Modal
                 isOpen={this.state.importModalIsOpen}
                 onAfterOpen={this.afterOpenImportModal}            
                 style={customStyles}
                 contentLabel="Import Single Shipstation Order"
               >                   
              
                 <div className="container-fluid">      
                  
                   <h2>Import Missing Shipstation Order</h2>
                       <div className="row">
                         <div className="col-sm-4 right">                                      
                           Order Number: 
                         </div>
                         <div className="col-sm-8">
                         <input
                             className="form-input"
                             id="or_number"
                             name="or_number"
                             type="text"
                             value={this.state.or_number}
                             onChange={this.handleInputChange}
                             placeholder=""                        
                           />
                         </div>         
                                                         
                       </div>
     
                    
                   <div className="modalMessage">{this.state.importstatus}</div>
                   <div className="right">
                     <button type="submit" onClick={this.getShipstationOrderById}>Import</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeImportModal}>Cancel</button>
                   </div>
                 
                  </div>                          
                 
               </Modal>
     
             </div>
         
             </div>
     </div>
             
                     )
         }
     }
     export default OrderListPaged;